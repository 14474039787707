<template>
<div id="registDevice" class="main home">
    <div class="home-box">
        <h1>新建档案</h1>
        <div class="home-content" style="padding-bottom:0;">
            <div class="title-block">用户信息</div>
            <el-form ref="form" :inline="true" :model="form" label-width="100px">
                <el-form-item label="用户编号" required>
                    <el-input class="inputItem" placeholder="请输入用户编号" v-model="form.eui" autofocus></el-input>
                </el-form-item>
                <el-form-item label="用户名称" required>
                    <el-input class="inputItem" placeholder="请输入用户名称" v-model="form.imei"></el-input>
                </el-form-item>
                <el-form-item label="用户地址" required>
                    <el-input class="inputItem" placeholder="请输入用户地址" v-model="form.imsi"></el-input>
                </el-form-item>
                <el-form-item label="用户电话" required>
                    <el-input class="inputItem" placeholder="请输入用户电话" v-model="form.imsi"></el-input>
                </el-form-item>
                <el-form-item label="小区名称">
                    <el-input class="inputItem" placeholder="请输入小区名称" v-model="form.imsi"></el-input>
                </el-form-item>
                <el-form-item label="开账日">
                    <el-input class="inputItem" placeholder="请填写开账日" v-model="form.imsi"></el-input>
                </el-form-item>
                <el-form-item label="开户日期" required>
                    <el-input class="inputItem" placeholder="请填写开户日期" v-model="form.imsi"></el-input>
                </el-form-item>
                <el-form-item label="用户备注">
                    <el-input class="inputItem" placeholder="请填写用户备注" v-model="form.imsi"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="home-content" style="padding-top: 0; padding-bottom: 0;">
            <div class="title-block">表具信息</div>
            <el-form ref="form" :inline="true" :model="form" label-width="100px">
                <el-form-item label="水表选择" required>
                    <el-select v-model="operatorValue" placeholder="请选择">
                        <el-option v-for="item in operatorList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="home-content" style="padding-top: 0;">
            <div class="title-block">收费模板</div>
            <el-form ref="form" :inline="true" :model="form" label-width="100px">
                <el-form-item label="水表选择" required>
                    <el-select v-model="operatorValue" placeholder="请选择">
                        <el-option v-for="item in operatorList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="滞纳金模板" required>
                    <el-select v-model="operatorValue" placeholder="请选择">
                        <el-option v-for="item in operatorList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="价格项目" required>
                    <el-select v-model="operatorValue" placeholder="请选择">
                        <el-option v-for="item in operatorList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :inline="true" :model="form" label-width="80px">
                <el-form-item>
                    <el-button type="success" size="mini" plain :loading="submitLoading" @click="onSubmit">注册</el-button>
                    <el-button size="mini" plain @click="back">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                imei: '',
                imsi: '',
                enterpriseId: '',
                deviceDesc: '',
                eui: '',
                moduleFactory: 1,
                type: ''

            },
            arrEnpr: [],
            dialogVisible: false,
            editState: false,
            operatorValue: '',
            operatorList: [{
                    value: '0',
                    label: '移动'
                },
                {
                    value: '1',
                    label: '电信'
                }
            ],
            deviceTypeValue: '',
            deviceTypeList: [],
            submitLoading: false
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.editState = true
            this.initCourseData(this.$route.query.id)
        } else {
            this.getAllEnprs()
        }
    },
    beforeDestroy() {

    },
    methods: {
        getAllEnprs() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        onSubmit() {
            console.log(this.form.seriesIdArr)
            if (this.form.eui.toString().trim() == "") {
                this.$message.warning('请输入设备编号')
                return
            }
            if (this.form.speaker.toString().trim() == "") {
                this.$message.warning('请输入主讲人名称')
                return
            }
            if (this.form.price.toString().trim() == "") {
                this.$message.warning('请输入价格')
                return
            }
            if (this.form.integralPrice.toString().trim() == "") {
                this.$message.warning('请输入需兑换积分')
                return
            }
            if (this.form.seriesIdArr.length == 0) {
                this.$message.warning('请选择系列')
                return
            }
            let that = this
            let params = {
                courseName: this.form.courseName,
                speaker: this.form.speaker,
                isHot: 1,
                price: this.form.price,
                courseTypeId: 0,
                seriesId: this.form.seriesIdArr[0],
                secondSeriesId: this.form.seriesIdArr.length > 1 ? this.form.seriesIdArr[1] : '',
                thirdSeriesId: this.form.seriesIdArr.length > 2 ? this.form.seriesIdArr[2] : '',
                videoType: this.form.videoType,
                integralPrice: this.form.integralPrice,
                defaultWatchCount: this.form.defaultWatchCount
            }
            console.log("@@@@@", params)
            if (this.form.courseId) {
                params.courseId = this.form.courseId
                this.$api.updateKecheng(params).then(res => {
                    console.log(res)
                    if (res.status == "OK") {
                        this.$message.success('保存成功')
                        setTimeout(() => {
                            that.$router.push({
                                path: '/'
                            })
                        }, 2000);
                    } else {
                        this.$message.error('保存失败')
                    }
                })
            } else {
                this.$api.saveKecheng(params).then(res => {
                    console.log(res)
                    if (res.status == "OK") {
                        this.$message.success('保存成功')
                        setTimeout(() => {
                            that.$router.push({
                                path: '/'
                            })
                        }, 2000);
                    } else {
                        this.$message.error('保存失败')
                    }
                })
            }
        },
        back() {
            history.back()
        }
    }
}
</script>

<style lang="scss">
#registDevice {
    .inputItem {
        width: 340px;
    }

    .el-input__inner {
        width: 340px;
    }

    .home-content {
        padding-top: 20px;
        padding-bottom: 20px;

        .what {
            width: 14px;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .el-form-item__content {

        align-items: center;
    }
}
</style>
