<template>
    <div class="in-report-manage">
        <component :is="views" @goEdit="goEdit"></component>
    </div>
    </template>
    
    <script>
    import sbList from '@/views/DAGL/components/yhdaList';
    import sbEdit from '@/views/DAGL/components/yhdaEdit';
    export default {
        data() {
            return {
                views: sbList
            }
        },
        methods: {
            goEdit(row) {
                console.log(row)
                this.$router.push({
                    path: '/caseManage/caseindex',
                    query: {
                        json: JSON.stringify(row)
                    }
                })
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.state) {
                this.views = sbEdit;
            } else if (this.$route.query && this.$route.query.id) {
                this.views = sbEdit;
            } else {
                this.views = sbList;
            }
        }
    }
    </script>
    